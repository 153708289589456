import { createApp } from 'vue';
import App from './App.vue';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/style/theme.less'; // 引入主题样式

import store from './store';
import router from './router';


const app = createApp(App);
// 设置 Vue.config.productionTip 为 false，禁用生产环境的提示信息
app.config.productionTip = false;


app.use(Antd).use(store).use(router).mount('#app');