<template>
    <div class="app-main">
        <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="帮助指引"
        sub-title="文档"
        @back="this.$router.back()"
        />
        <a-divider orientation="left" orientation-margin="12px">
            问题列表
        </a-divider>

        <div class="html-content">
            {{ documentContent }}
            <p></p>

        </div>
    </div>
	<BottomDemo current="index" />
</template>

<script>
import BottomDemo from '../components/BottomDemo.vue';

export default {
    data() {
        return {
            documentContent: '',
        }
    },
    components: {
        BottomDemo
    },
    created() {
        window.scrollTo(0, 0);
    },
    mounted() {
        
    },
    methods: {
        fetchHotKeyword() {
            const url = 'https://yqapi.fkdlapp.live/index/helpdoc';
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.documentContent = data['data'];
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
    },
    computed: {

    }
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}


#cate-card-list {
    display: flex;
    overflow: scroll;
    white-space: nowrap;
    padding-right: 17px;
}
.cate-card {
    position: relative;
    height: 108px;
    width: 88px;
    border-radius: 10px;
    margin-right: 12px;
}
.cate-card img {
    
}
.cate-card p {
    height: 108px;
    line-height: 108px;
    width: 88px;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.7));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
}

#jp-actress-list {
overflow: scroll;
    white-space: nowrap;
}

.jp-actress-item {
display: inline-block; width: 64px;text-align: center;
    margin: 8px;
}


:deep(.ant-card-meta-detail>.ant-card-meta-title) {
  font-size: 18px;
  font-weight: bold;
}

</style>