//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import IndexPage from '../views/IndexPage.vue'
import CategoryView from '../views/CategoryView.vue'
import HotKeyword from '../views/HotKeyword.vue'
import VideoList from '../views/VideoList.vue'
import VideoSearch from '../views/VideoSearch.vue'
import VideoPlay from '../views/VideoPlay.vue'
import MyFavorite from '../views/MyFavorite.vue'
import MyHistory from '../views/MyHistory.vue'
import YouVideoList from '../views/YouVideoList.vue'
import YouVideoPlay from '../views/YouVideoPlay.vue'
import ComicList from '../views/ComicList.vue'
import ComicView from '../views/ComicView.vue'
import PhotoList from '../views/PhotoList.vue'
import PhotoView from '../views/PhotoView.vue'
import NovelList from '../views/NovelList.vue'
import NovelView from '../views/NovelView.vue'

import LiveRoom from '../views/LiveRoom.vue'
import LivePlay from '../views/LivePlay.vue'
import PostList from '../views/PostList.vue'
import PostVideoPlay from '../views/PostVideoPlay.vue'
import ShortVideo from '../views/ShortVideo.vue'

import LoginView from '../views/LoginView.vue'
import UserHome from '../views/UserHome.vue'
import MyVip from '../views/MyVip.vue'
import PasswordUpdate from '../views/PasswordUpdate.vue'
import HelpDoc from '../views/HelpDoc.vue'


const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  //history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {path: '/', name: 'index', component: IndexPage},
    {path: '/category', name: 'category', component: CategoryView},
    {path: '/hotkeyword', name: 'hotkeyword', component: HotKeyword},
    {path: '/videolist', name: 'videolist', component: VideoList},
    {path: '/videosearch', name: 'videosearch', component: VideoSearch},
    {path: '/videoplay', name: 'videoplay', component: VideoPlay},
    {path: '/favorite', name: 'favorite', component: MyFavorite},
    {path: '/history', name: 'history', component: MyHistory},
    {path: '/youvideolist', name: 'youvideolist', component: YouVideoList},
    {path: '/youvideoplay', name: 'youvideoplay', component: YouVideoPlay},
    {path: '/comiclist', name: 'comiclist', component: ComicList},
    {path: '/comicview', name: 'comicview', component: ComicView},
    {path: '/photolist', name: 'photolist', component: PhotoList},
    {path: '/photoview', name: 'photoview', component: PhotoView},
    {path: '/novellist', name: 'novellist', component: NovelList},
    {path: '/novelview', name: 'novelview', component: NovelView},

    {path: '/liveroom', name: 'liveroom', component: LiveRoom},
    {path: '/liveplay', name: 'liveplay', component: LivePlay},
    //{path: '/postlist', name: 'postlist', component: () => import('../views/PostList.vue')},
    {path: '/postlist', name: 'postlist', component: PostList},
    {path: '/postvideoplay', name: 'postvideoplay', component: PostVideoPlay},
    {path: '/shortvideo', name: 'shortvideo', component: ShortVideo},

    {path: '/login', name: 'login', component: LoginView},
    {path: '/userhome', name: 'userhome', component: UserHome},
    {path: '/myvip', name: 'myvip', component: MyVip},
    {path: '/password', name: 'password', component: PasswordUpdate},
    {path: '/helpdoc', name: 'helpdoc', component: HelpDoc},
  ]
})

export default router
