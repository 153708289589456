<template>
    <div class="app-main">
        <a-page-header
            style="border: 1px solid rgb(235, 237, 240)"
            title="会员中心"
            sub-title="登录 / 注册"
            @back="this.$router.back()"
            />
        <div style="width: 80%; margin: 12px auto;">
            <a-tabs v-model:select-key="activeTab" v-model:activeKey="activeKey"  size="large" centered>
                <a-tab-pane key="login" tab="登 录">
                    <a-form
                        :model="loginState"
                        name="login_form"
                        class="login-form"
                        @submit.prevent="loginSubmit"
                        >
                        <a-form-item
                          label="账号（手机号）"
                          name="username">
                          <a-input v-model:value="loginState.username" size="large">
                            <template #prefix>
                              <!--UserOutlined class="site-form-item-icon" /-->
                                <svg t="1722633137095" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="87054" width="20" height="20"><path d="M731.428571 97.52381a73.142857 73.142857 0 0 1 73.142858 73.142857v682.666666a73.142857 73.142857 0 0 1-73.142858 73.142857H292.571429a73.142857 73.142857 0 0 1-73.142858-73.142857V170.666667a73.142857 73.142857 0 0 1 73.142858-73.142857h438.857142z m0 73.142857H292.571429v682.666666h438.857142V170.666667z m-219.428571 536.380952a48.761905 48.761905 0 1 1 0 97.52381 48.761905 48.761905 0 0 1 0-97.52381z m97.52381-499.809524v73.142857h-195.04762v-73.142857h195.04762z" fill="#aabbcc" p-id="87055"></path></svg>
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item
                          label="密码"
                          name="password">
                            <a-input-password v-model:value="loginState.password" size="large">
                                <template #prefix>
                                <!--LockOutlined class="site-form-item-icon" /-->
                                    <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                                </template>
                            </a-input-password>
                        </a-form-item>
                
                    <!--a-form-item>
                      <a-form-item name="remember" no-style>
                        <a-checkbox v-model:checked="formState.remember">Remember me</a-checkbox>
                      </a-form-item>
                      <a class="login-form-forgot" href="">Forgot password</a>
                    </a-form-item-->
                
                        <a-form-item>
                            <a-button  type="primary" html-type="submit" class="login-form-button" size="large" block>
                                立即登录
                            </a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
                <a-tab-pane key="reg" tab="注 册" force-render>
                    <a-form
                        :model="regState"
                        name="reg_form"
                        class="login-form"
                        @submit.prevent="regSubmit"
                        >
                        <a-form-item
                          label="账号（手机号）"
                          name="username">
                          <a-input v-model:value="regState.username" size="large">
                            <template #prefix>
                                <svg t="1722633137095" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="87054" width="20" height="20"><path d="M731.428571 97.52381a73.142857 73.142857 0 0 1 73.142858 73.142857v682.666666a73.142857 73.142857 0 0 1-73.142858 73.142857H292.571429a73.142857 73.142857 0 0 1-73.142858-73.142857V170.666667a73.142857 73.142857 0 0 1 73.142858-73.142857h438.857142z m0 73.142857H292.571429v682.666666h438.857142V170.666667z m-219.428571 536.380952a48.761905 48.761905 0 1 1 0 97.52381 48.761905 48.761905 0 0 1 0-97.52381z m97.52381-499.809524v73.142857h-195.04762v-73.142857h195.04762z" fill="#aabbcc" p-id="87055"></path></svg>
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item
                          label="密码"
                          name="password">
                            <a-input-password v-model:value="regState.password" size="large">
                                <template #prefix>
                                    <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="确认密码"
                          name="repwd">
                            <a-input-password v-model:value="regState.repwd" size="large">
                                <template #prefix>
                                    <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="邀请码（必填）"
                          name="invitation_code">
                          <a-input v-model:value="regState.invcode" size="large">
                            <template #prefix>
                              <!--NumberOutlined class="site-form-item-icon" /-->
                                <svg t="1722633432759" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="91025" width="20" height="20"><path d="M900.608 498.304a38.336 38.336 0 0 0-38.72-5.12l-338.688 144.64-338.688-157.312a37.888 37.888 0 0 0-39.52 5.12c-10.656 8.704-16.992 22.944-16.992 37.568v348.96c0 25.28 18.56 46.24 41.504 46.24h707.392c22.944 0 41.92-20.96 41.504-46.24v-336.32c0-15.04-6.72-28.832-17.792-37.536z m-80.224-46.24V181.76c0.416-29.248-20.544-53.376-47.04-53.76H273.056c-26.464 0.384-47.424 24.512-47.04 53.76v258.464l297.216 138.688 297.184-126.848z m-307.84 20.16c-0.416 0-0.416 0 0 0l-100.384-107.904-1.6-2.752c-17.76-20.16-24.896-48.64-18.976-75.872 5.536-29.664 26.112-53.376 52.96-60.864 28.864-7.904 59.296 5.536 75.104 32.8 16.192-27.264 46.24-40.704 75.072-32.8 26.88 7.488 47.424 31.2 52.96 60.864a90.752 90.752 0 0 1-18.976 76.256h-0.768c-0.8 0.8-1.6 1.6-2.368 2.784l-100.384 107.872c-3.168 3.552-9.088 3.552-12.64-0.384z" fill="#aabbcc" p-id="91026"></path></svg>
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button  type="primary" html-type="submit" class="login-form-button"  size="large" block>
                                确认注册
                            </a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
    <BottomDemo current="userhome" />
</template>

<script>
import { message } from 'ant-design-vue'; // 导入消息提示组件
import BottomDemo from '../components/BottomDemo.vue';

export default {
  components: {
    BottomDemo
  },
  data() {
    return {
      activeTab: 'reg',
      activeKey: 'login',
      loginState: {
        username: '',
        password: '',
      },
      regState: {
        username: '',
        password: '',
        repwd: '',
        invcode: '', //invitation code
      },
    }
  },
  created() {
      window.scrollTo(0, 0);
  },
  methods: {
    async loginSubmit() {
        // 登录成功后将用户名和令牌保存到localStorage中
        /*
        const username = '123123';
        const vip = 1;
        const endtime = 1798867821;
        const token = '123123';
        localStorage.setItem('userinfo', JSON.stringify({ username, vip, endtime, token }));*/
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.loginState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送登录请求
        const response = await fetch('https://yqapi.fkdlapp.live/userAction/login', requestOptions);
        const data = await response.json();

        // 处理登录成功的情况
        if (data.success) {
          // 登录成功，执行跳转等操作
          message.success('登录成功，欢迎回来！', 3);
          localStorage.setItem('userdata', JSON.stringify(data.data));
          this.$router.push('/userhome');
        } else {
          // 登录失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.loginState);
        console.log('Login error:', error);
      }
    },
    async regSubmit() {
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.regState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送注册请求
        const response = await fetch('https://yqapi.fkdlapp.live/userAction/reg', requestOptions);
        const data = await response.json();

        // 处理注册成功的情况
        if (data.success) {
          // 注册成功，执行跳转等操作
          message.success('注册成功！', 3);
          this.loginState.username = data.data.username;
          this.activeTab="login";
          this.activeKey="login";
        } else {
          // 注册失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.loginState);
        console.log('Login error:', error);
      }
    },
  }
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
</style>