<template>
    <div class="app-main">
        <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="热搜排行"
        sub-title="关键词"
        @back="this.$router.back()"
        />
        <!--a-divider orientation="left" orientation-margin="12px">
            热词
        </a-divider-->

        <div class="keyword-list" style="width: 100%;">
            <!--a-button size="large" class="keyword-button" block><a-tag color="#f35" >1</a-tag>词哈哈(902834)</a-button-->
            <a-button
                v-for="(item, index) in hotKeyword"
                :key="item.id"
                @click="$router.push({path:'/videosearch', query: {'keyword': item.keyword}})"
                size="large"
                class="keyword-button"
                block>
                <a-tag :color="sortColor(index)">
                {{ index+1 }}
                </a-tag>
                {{ item.keyword }}<span style="color: #aabbcc;">({{ item.search_count }})</span>
            </a-button>
        </div>
        <div class="ad-banner">
            <a v-for="item in adverBanner" :key="item.id" :href="item.link" target="_blank">
                <img :src="item.img" />
            </a>
        </div>
    </div>
	<BottomDemo current="index" />
</template>

<script>
import BottomDemo from '../components/BottomDemo.vue';

export default {
    data() {
        return {
            hotKeyword: [],
            adverBanner: [],
        }
    },
    components: {
        BottomDemo
    },
    created() {
        window.scrollTo(0, 0);
        this.fetchHotKeyword();
    },
    mounted() {
        
    },
    methods: {
        fetchHotKeyword() {
            const url = 'https://yqapi.fkdlapp.live/index/hotkeyword';
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.hotKeyword = data['data']['keywords'];
                    this.adverBanner = data['data']['adver_banner'];
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        sortColor(sortnum) {
            switch(sortnum) {
                case 0:
                    return "#f35";
                case 1:
                    return "red";
                case 2:
                    return "orange";
                case 3:
                    return "green";
                default:
                    return "";
            }
        },
    },
    computed: {

    }
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}

.keyword-list {
    padding: 17px 12px;
}
.keyword-button {
    margin-right: 12px;
    margin-bottom: 17px;
    text-align: left;
}
</style>