<template>
    <div class="app-main">
        <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="在线漫画"
        sub-title="浏览"
        @back="this.$router.back()"
        />
        <div style="padding: 12px;">
            <h3 style="margin: 12px 0;">{{ title }}</h3>
            <div>
                <a-image
                    v-for="item in comics"
                    :key="item.id"
                    :src="item"
                    style="margin-top: 24px;"
                    />
            </div>
        </div>
    </div>
    <BottomDemo current="category" />
</template>

<script>
import { Modal } from 'ant-design-vue';
import BottomDemo from '../components/BottomDemo.vue';

export default {
    data() {
        return {
            id: '',
            title: '',
            comics: [],
        }
    },
    components: {
        BottomDemo
    },
    created() {
        window.scrollTo(0, 0);
        this.id = this.$route.query.id;
        this.fetchComicView(this.$route.query.id);
    },
    mounted() {
        
    },
    methods: {
        fetchComicView(id) {
            const userdata = localStorage.getItem('userdata');
            if (userdata) {
                const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
                const token = parsedUserData.token;
                const url = 'https://yqapi.fkdlapp.live/getData/comicview/id/'+id+'/token/'+token;
                fetch( url )
                    .then(response => response.json())
                    .then(data => {
                        // 请求成功，处理数据
                        if (data['success']) {
                            this.title = data['data']['title'];
                            this.comics = data['data']['imgs'];
                        } else {
                            //Modal.confirm({
                            Modal.warning({
                                title: '提示',
                                content: data['msg'],
                                okText: data['data']['button_title'],
                                onOk: () => {
                                    this.$router.push({ path: data['data']['button_router'] });
                                    //this.$router.back();
                                },
                                //cancelText: '关闭',
                                centered: true,
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误
                        this.error = error.message;
                    });
            } else {
                this.$router.push({ path: '/login' });
            }
        },
    },
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
</style>