<template>
    <div class="app-main">
        <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="社区"
        sub-title="精彩黑料等你挖掘"
        @back="this.$router.back()"
        />

        <a-carousel :autoplay="4000" effect="scrollx" arrows>
            <template #prevArrow>
                <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                    <left-circle-outlined :style="{fontSize: '20px', color: '#fff'}" />
                </div>
            </template>
            <template #nextArrow>
                <div class="custom-slick-arrow" style="right: 10px">
                    <right-circle-outlined :style="{fontSize: '20px', color: '#fff'}" />
                </div>
            </template>
            <div v-for="item in sliderAD" :key="item.id">
                <a :href="item.link" target="_blank">
                    <img :src="item.picname" :alt="item.title" style="width: 100%;" />
                </a>
            </div>
        </a-carousel>

        <!--div style="padding: 12px; text-align: left;">
            <a-space direction="vertical" style="width: 100%">
                <a-alert :message="notice" type="warning" show-icon>
                    <template #icon><sound-filled /></template>
                </a-alert>
            </a-space>
        </div-->

        <div style="padding: 12px;">
           <a-divider>最新帖子</a-divider>
              <div v-for="item in posts" :key="item.id">
              <a-comment>
                <template #actions>
                    <span style="cursor: auto">
                        <svg t="1722613611703" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="74181" width="15" height="15"><path d="M933.568 374.912a56.896 56.896 0 0 0-51.84-33.6h-245.76L536.512 92.8a56.896 56.896 0 0 0-105.792 0L331.072 341.312h-245.76a56.896 56.896 0 0 0-40.96 96.192L232.704 634.88l-87.68 257.152a56.896 56.896 0 0 0 83.072 67.136l255.488-153.024 255.36 152.96a56.896 56.896 0 0 0 85.952-64.256L752.64 634.88l174.08-199.104c13.952-17.28 16.64-40.96 6.848-60.864z" fill="#ffdd00" p-id="74182"></path></svg>
                        {{ item.favorited }}
                    </span>
                    <span>
                        <svg t="1722882037154" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="99886" width="15" height="15"><path d="M864.198891 383.935495l-189.215522 0c13.761129-31.994625 29.586427-114.733412 20.297665-189.731564C688.056442 134.171006 652.965564 88.071225 601.705359 70.697799c-38.015118-12.901058-78.26642-6.70855-99.940198 15.48127-25.802117 26.318159-37.843104 75.858223-50.744163 128.322526-8.428691 34.402822-17.029397 69.837729-27.866286 88.243239-31.134554 52.980346-96.67193 81.362674-109.572988 81.362674l-89.619352 0c-17.717453 0-31.994625 14.449185-31.994625 32.166639l0 447.924744c0 17.717453 14.277171 31.994625 31.994625 31.994625l86.523098 0c26.834201 0 126.430371 63.989249 241.851839 63.989249l215.877709 0c35.606921 0 96.843944-13.761129 119.721821-51.776247 23.049891-38.531161 71.901898-388.75189 71.901898-427.455065C959.838737 427.111036 907.546447 383.935495 864.198891 383.935495z" fill="#cc77ff" p-id="99887"></path><path d="M95.983874 384.107509c-17.717453 0-31.994625 14.277171-31.994625 31.994625l0 447.924744c0 17.717453 14.277171 31.994625 31.994625 31.994625s31.994625-14.277171 31.994625-31.994625L127.978498 416.102133C127.978498 398.38468 113.701327 384.107509 95.983874 384.107509z" fill="#cc77ff" p-id="99888"></path></svg>
                        {{ item.liked }}
                    </span>
                    <span>
                        <svg t="1722882184739" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="101084" width="15" height="15"><path d="M847.36 107.52h-665.6c-69.12 0-125.44 56.32-125.44 125.44v401.92c0 69.12 56.32 125.44 125.44 125.44h38.4l15.36 181.76 158.72-181.76h453.12c69.12 0 125.44-56.32 125.44-125.44V232.96c0-69.12-56.32-125.44-125.44-125.44z m-563.2 376.32c-33.28 0-61.44-28.16-61.44-61.44 0-33.28 28.16-61.44 61.44-61.44 33.28 0 61.44 28.16 61.44 61.44 2.56 33.28-25.6 61.44-61.44 61.44z m230.4 0c-33.28 0-61.44-28.16-61.44-61.44 0-33.28 28.16-61.44 61.44-61.44 33.28 0 61.44 28.16 61.44 61.44 0 33.28-28.16 61.44-61.44 61.44z m227.84 0c-33.28 0-61.44-28.16-61.44-61.44 0-33.28 28.16-61.44 61.44-61.44 33.28 0 61.44 28.16 61.44 61.44 2.56 33.28-25.6 61.44-61.44 61.44z" fill="#44ddff" p-id="101085"></path></svg>
                        {{ item.comment_count }}
                    </span>
                </template>
                <template #author>{{ item.source_uname }}</template>

                <template #avatar>
                  <a-avatar src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABGpJREFUeF7tm11uGjEQgMfAPUqOUJU8B6QSqQcoVZ+anKThJCFPVekBKoVKIc+h6hGy9yiLW8MuMYvXnr+FShQpDxHe9cw3Px6PjYET/5gT1x/+AzimB3z5/qv78d3r7JgyHMQDnKKtzvLKgLlYK2uhCwa6O4pb2IAwkLk/Y+3d++H5vGk4jQDYKmzMK7BwxVbCQWnBHFbmMc9b8ya8RRWAZ+nPbKXrHrSQWWPvVsvORBOECoBGFa8CKUB8GJ7faEAWA/g6e7oxYPQtntJOCYQIwPTH4lYU4yklEd9bsGOJN7AAOJdvd/JbAOgjZGx+iIUsz9sDTm4gA/g2e+pbMA/Na0WcgQmBBEBN+SJ+jW29FEHGfhJ7lIXMGHtNqR/QAAq3fybaZXe4gUn+uz2uc1WV1YToCWgA09nCuT0/5q25Hl2+mWAAilcWC9nosneGmQsFQKo8J1OLIRiYjN72rlMQkgDEggDMR8PeICVI6PtDgE8CmM4WliN8+YwBO6AkJX8ucdJFhEIUgIL1YTTsJSHHAE/vF897O0eKRRKhEBVOav2/gqPiMApAnHzjRVItAJUyVwOARrkdkaMegDD2i+aG3AM0AERqgyCA6f3PKzDW1frSD3sFKCcW54DyRTV1SBiABnU3MSILpwiL89DLBEFjhAFouP/LOsgOA41VaKt/jTH2ACi6/2ZuYm1eCqyy96i4V6gm2Qeg5f7+5IxQkFaBodAKleSHAUDwhIabLXt5IBACwsorltWKPkALYF4tj1W2wqmMGtiXHBZAJSy2/1YPSdKK8EYEQnEfgOYKwBOz0aeqe5OTA5Av22d+R2oHQBNLT6PmZLw8CsC9T7HyYojX/CPpEJDuv1M6VE6Bi2KpPCnm9xxT89aU5qEcIGt+VgWJLH0hmV0YdjrL7gqgXxyna0JB1AHSBkRB2p3kSo6s/JLYu1sggxHoC+x5gGgDsnZvM8a2vzFe648p9in8AxQMAG4jktP6pgIox3ONhNoMkZdCxnEUV3H/ubWc7fyB0jANNWjr+gHoRChpe0tBkLy1pi8oa4kpND2lENDNW0pLDB0GhPM+qaJ1z2O9oFoBlu8TtcUPmfjqAKA6WJy2OMoLjpQASxhS67v3xE+GMO1xZs9PGhIoA61bkvE7RFEA2Ek02t9UINieYepsMnlwiYqxovzlXlSiKE9Z/1PWT4YAufJSurtXB4RYAaJOpZIe4IQhd2qV8wJ5fsKlDBSALQRK6elddOZujgqLuxvm+F0g8QwCDcDzBPpNMa8nsFx2stAtMWdlN4fXC2Bdv6WW5iQALE8IBbTfFdp8j7dwXYJg1iRkAGoQKKk/NZbo9v7rWABKCEWnhuWqKZ3Q3ws3ZGwA5CUSrRFyoNKSKwZwcG9QUjy5G0TaYWdY0wecmMqOKreKB1Qn3ZSrqz607IXoBxVutTCQWbCPGh3mEJxGAPgTBfr8yZ/NOYVDR+hU62LGNw4gJsTJ/HASY4ljjTmqBxxLaZVC6F8QXkOGP5q0xF8wbZEmAAAAAElFTkSuQmCC">
                  </a-avatar>
                </template>
                <template #content>
                  <p >
                    {{ item.title }}
                  </p>
                  <div style="margin-top: 7px;">
                      <a-row>
                        <template v-if="item.video && item.cover">
                            <a-col :span="8">
                                <!--a-image :data-src="fetchImageData(item.cover)" :src="item.cover" style="width: 100%;"/-->
                                <div style="position: relative;" @click="handleVideoClick(item.id)">
                                    <div class="playbtn">
                                        <svg t="1722881736570" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="98670" data-spm-anchor-id="a313x.search_index.0.i151.12223a81x44nEN" width="57" height="57"><path d="M512 97.52381c228.912762 0 414.47619 185.563429 414.47619 414.47619s-185.563429 414.47619-414.47619 414.47619S97.52381 740.912762 97.52381 512 283.087238 97.52381 512 97.52381z m-57.782857 268.190476c-21.942857 0-39.740952 17.798095-39.740953 39.740952v209.13981a39.740952 39.740952 0 0 0 61.196191 33.426285l164.352-105.593904a39.740952 39.740952 0 0 0-0.292571-67.047619l-164.352-103.545905a39.740952 39.740952 0 0 0-21.187048-6.119619z" p-id="98671" data-spm-anchor-id="a313x.search_index.0.i152.12223a81x44nEN" class="" fill="#f2f4f6"></path></svg>
                                    </div>
                                    <img :src="item.cover" style="width: 100%;"/>
                                </div>
                            </a-col>
                        </template>
                        <a-col :span="8" v-for="(imgurl, index) in item.imgs" :key="index">
                            <!--a-image :data-src="fetchImageData(imgurl)" :src="imgurl" style="width: 100%;"/-->
                            <a-image :src="imgurl" style="width: 100%;"/>
                        </a-col>
                      </a-row>
                  </div>
                </template>
                <template #datetime>
                  <a-tooltip>
                    <span>{{ item.create_time }}</span>
                  </a-tooltip>
                </template>
              </a-comment>
              <a-divider style="margin: 0;" />
            </div>
            <a-pagination page-size="20" :current="pageCurrent" :total="pageTotal" @change="handlePageChange" />
                <!--a-tab-pane key="2" tab="Tab 2" force-render>Content of Tab Pane 2</a-tab-pane-->
        </div>
    </div>
	<BottomDemo current="category" />
</template>

<script>
import { Modal } from 'ant-design-vue';
import BottomDemo from '../components/BottomDemo.vue';
import CryptoJS from 'crypto-js';

export default {
    components: {
        BottomDemo
    },
    data() {
        return {
            theme: 'dark',
            //cid: null,
            menuCurrent: 20,
            pageCurrent: 1,
            pageTotal: null,
            cateTitle: '分类名称',
            posts: [],
            notice: '',
        };
    },
    created() {
        window.scrollTo(0, 0);
        //this.cid = this.$route.query.cid;
        if ( this.$route.query.page > 1 ) {
            this.pageCurrent = this.$route.query.page;
        }
        this.fetchPostList();
    },
    computed: {
        categories() {
            return this.$store.state.categories;
        },
        sliderAD() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 1);
        },
        listAd1() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 4);
        },
        listAd2() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 5);
        },
    },
    mounted() {
        
    },
    methods: {
        fetchPostList() {
            //const cid = this.cid
            const page = this.pageCurrent;
            //const url = 'https://api.koudailc.net/api/blog/list?page='+page;
            const url = 'https://yqapi.fkdlapp.live/getData/postlist/page/'+page;
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.posts = data['data']['posts'];
                    this.pageTotal = data['data']['page_total'];
                    this.parseImages();
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        /*
        sendHttpRequest(url) {
            return fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch data. Status: ' + response.status);
                    }
                    return response.arrayBuffer();
                });
        },*/
        parseImages() {
            const postsData = this.posts;
            postsData.forEach(post=> {
                if (post.cover) {
                    return fetch( post.cover )
                    .then(response => response.arrayBuffer())
                    .then(data => {
                        const imgBase64 = this.decryptImage(data, post.cover);
                        post.cover = imgBase64;
                    });
                }
                if (post.imgs && post.imgs.length>0) {
                    post.imgs.forEach((imgurl, index)=> {
                        //post.imgs[index] = 'https://cdn.sofan.one:65533/static/img/douyin.png';
                        return fetch( imgurl )
                        .then(response => response.arrayBuffer())
                        .then(data => {
                            const imgBase64 = this.decryptImage(data, imgurl);
                            post.imgs[index] = imgBase64;
                        });
                    });
                }
            });
        },
        replaceImageSRC(url) {
            return fetch( url )
                .then(response => response.arrayBuffer())
                .then(data => {
                    const imgdata = this.decryptImage(data, url);
                    const imgElement = document.querySelector('img[src="' + url + '"]');
                    if (imgElement) {
                        imgElement.src = imgdata;
                    }
                });
        },
        fetchImageData(url) {
            return fetch( url )
                .then(response => response.arrayBuffer())
                .then(data => {
                    const imgBase64 = this.decryptImage(data, url);
                    return imgBase64;
                });
        },
        decryptImage(imgdata, url) {
            const completeFilename = new URL(url).pathname.split('/').pop();
            console.log("url:"+url);
            const filename = completeFilename.split('.')[0];
            //const extname = completeFilename.split('.')[1];
            const key = CryptoJS.MD5(filename);
            //var b64data = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
            const unit8data = new Uint8Array(imgdata);
            const encryptedData = CryptoJS.lib.WordArray.create(unit8data);
            const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedData);
            const decryptedData = CryptoJS.AES.decrypt(encryptedBase64, key, { iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
            const decryptedBase64 = decryptedData.toString(CryptoJS.enc.Base64);
            const imageSrc = "data:image/png;base64," + decryptedBase64;
            return imageSrc;
        },
        handlePageChange(page) {
            this.pageCurrent = page;
            this.$router.push({ path: '/postlist', query: { 'page': page } });
            this.fetchPostList();
        },
        handleVideoClick(id) {
            const userdata = localStorage.getItem('userdata');
            if (userdata) {
                const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
                // eslint-disable-next-line no-unused-vars
                const viptime = parsedUserData.viptime;
                const myvip = parsedUserData.myvip;
                // eslint-disable-next-line no-unused-vars
                const token = parsedUserData.token;
                if ( myvip == true ) {
                    // 如果VIP有效，则执行路由跳转
                    this.$router.push({ path: '/postvideoplay', query: { id: id } });
                } else {
                    // 如果非VIP会员，则弹出对话框
                    Modal.confirm({
                        title: '提示',
                        content: 'VIP资源仅VIP会员可观看哦~',
                        okText: '立即开通',
                        onOk: () => {
                            // 处理登录逻辑，例如跳转至登录页面
                            this.$router.push({ path: '/userhome' });
                        },
                        cancelText: '关闭',
                        centered: true,
                    });
                }
            } else {
                // 如果未登录，则弹出对话框
                Modal.confirm({
                    title: '提示',
                    content: '需要登录后才可观看哦~',
                    okText: '立即登录',
                    onOk: () => {
                        // 处理登录逻辑，例如跳转至登录页面
                        this.$router.push({ path: '/login' });
                    },
                    cancelText: '关闭',
                    centered: true,
                });
            }
        },
    },
}
</script>


<!--style scoped-->
<style>
.app-main {
    padding: 0 0 108px 0;
}
#top-menu.menu-container ul {
    margin-right: -25px!important;
}
#top-menu.menu-container ul>li {
    margin-right: -8px!important;
}
.ant-comment .ant-comment-inner {
    padding-bottom: 8px!important;
}
.playbtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: #fff;
}

</style>