<template>
    <div class="app-main">
        <a-page-header
            style="border: 1px solid rgb(235, 237, 240)"
            title="会员中心"
            sub-title="修改密码"
            @back="this.$router.back()"
            />
        <div style="width: 80%; margin: 12px auto;">
                    <a-form
                        :model="passwordState"
                        name="password_form"
                        class="password-form"
                        @submit.prevent="passwordSubmit"
                        >
                        <a-form-item
                          label="原密码"
                          name="password">
                          <a-input-password v-model:value="passwordState.password" size="large">
                            <template #prefix>
                              <!--LockOutlined class="site-form-item-icon" /-->
                                <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                            </template>
                          </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="新密码"
                          name="newpwd">
                            <a-input-password v-model:value="passwordState.newpwd" size="large">
                                <template #prefix>
                                    <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="确认新密码"
                          name="renewpwd">
                            <a-input-password v-model:value="passwordState.renewpwd" size="large">
                                <template #prefix>
                                    <svg t="1722633184727" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="88243" width="20" height="20"><path d="M775.830091 388.443346h-70.590665c0-113.381009-86.656855-200.039821-197.104821-200.039822-110.602543 0-197.106778 86.658812-197.106778 200.039822h-70.743285c0-136.090083 120.023893-270.632443 267.697443-270.632443S775.837918 252.355219 775.837918 388.443346h-0.007827z m89.595769 129.296536c0-38.925928-31.668651-70.588708-70.592622-70.588708H229.162849c-38.929841 0-70.592622 31.662781-70.592622 70.588708v321.91472c0 38.927884 31.666694 70.590665 70.592622 70.590665h565.513856c38.927884 0 70.590665-31.666694 70.590665-70.590665V517.739882h0.15849z m-297.511174 232.810075c0 29.193467-23.638491 52.980665-52.980664 52.980665h-7.568387c-29.197381 0-52.982621-23.636534-52.982621-52.980665v-143.656513c0-29.195424 23.636534-52.982621 52.982621-52.982621h7.568387c29.193467 0 52.980665 23.636534 52.980664 52.982621v143.656513z" fill="#aabbcc" p-id="88244"></path></svg>
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item>
                            <a-button  type="primary" html-type="submit" class="login-form-button" size="large" block>
                                确认修改
                            </a-button>
                        </a-form-item>
                    </a-form>
        </div>
    </div>
    <BottomDemo current="userhome" />
</template>

<script>
import { message } from 'ant-design-vue';
import BottomDemo from '../components/BottomDemo.vue';

export default {
  data() {
    return {
      passwordState: {
        username: '',
        password: '',
        newpwd: '',
        renewpwd: '',
      },
    }
  },
  components: {
      BottomDemo
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    async passwordSubmit() {
      const userData = JSON.parse(localStorage.getItem('userdata'));
      this.passwordState.username = userData.username;
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.passwordState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送修改密码请求
        const response = await fetch('https://yqapi.fkdlapp.live/userAction/pwd_update', requestOptions);
        const data = await response.json();

        // 处理修改成功的情况
        if (data.success) {
          message.success('修改成功', 3);
        } else {
          // 修改失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.passwordState);
        console.log('Login error:', error);
      }
    },

  }
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
</style>