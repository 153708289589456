<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue'

const props = defineProps({
    unitText: String
})

// 倒计时结束时间（例如：当前时间 + 1小时）
const endTime = ref(new Date().getTime() + 2*60*60*1000)

const timeLeft = ref('')

// 格式化时间为 hh:mm:ss
const formatTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000)
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0')
  const seconds = String(totalSeconds % 60).padStart(2, '0')

  if (props.unitText == 'zhcn') {
    return `${hours}小时${minutes}分${seconds}秒`
  } else {
    return `${hours} : ${minutes} : ${seconds}`
  }
}

// 更新倒计时
const updateCountdown = () => {
  const now = new Date().getTime()
  const remainingTime = endTime.value - now
  if (remainingTime <= 0) {
    timeLeft.value = '00:00:00'
    clearInterval(timer)
  } else {
    timeLeft.value = formatTime(remainingTime)
  }
}

// 设置倒计时定时器
let timer

onMounted(() => {
  updateCountdown()
  timer = setInterval(updateCountdown, 1000)
})

onUnmounted(() => {
  clearInterval(timer)
})
</script>

<template>
    <span class="timer">{{ timeLeft }}</span>
</template>


<style scoped>
.timer {
    color: #ff0000;
}
</style>
