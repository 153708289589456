<template>
    <div class="app-main">
        <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="美女写真"
        sub-title="列表"
        @back="this.$router.back()"
        />
        <a-divider orientation="left" orientation-margin="12px">
            最新
        </a-divider>
        <div style="padding: 12px;">
            <a-row :gutter="[16,16]">
                <a-col :span="12" v-for="item in photos" :key="item.id">
                    <a-card data-hoverable style="" @click.prevent="handleCardClick(item.id)">
                        <template #cover>
                            <img
                                :height="200"
                                alt=""
                                :src="item.cover" />
                        </template>
                        <a-card-meta :title="item.title">
                            <template #description></template>
                        </a-card-meta>
                    </a-card>
                </a-col>
            </a-row>
        </div>
        <div style="padding: 12px;">
            <a-pagination page-size="10" :current="pageCurrent" :total="pageTotal" @change="handlePageChange" />
        </div>
    </div>
    <BottomDemo current="category" />
</template>

<script>
import { Modal } from 'ant-design-vue'; // 导入对话框组件
import BottomDemo from '../components/BottomDemo.vue';

export default {
    data () {
        return {
            pageCurrent: 1,
            pageTotal: null,
            photos: [],
        }
    },
    components: {
        BottomDemo
    },
    created() {
        window.scrollTo(0, 0);
        this.fetchPhotoList();
    },
    mounted() {
        
    },
    methods: {
        fetchPhotoList() {
            const page = this.pageCurrent;
            const url = 'https://yqapi.fkdlapp.live/getData/photolist/page/'+page;
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.photos = data['data']['photos'];
                    //this.cateTitle = data['data']['category']['title'];
                    this.pageTotal = data['data']['page_total'];
                    console.log(this.photos);
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        handleCardClick(pid) {
            const userdata = localStorage.getItem('userdata');
            if (userdata) {
                const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
		// eslint-disable-next-line no-unused-vars                
		const viptime = parsedUserData.viptime;
                const myvip = parsedUserData.myvip;
		// eslint-disable-next-line no-unused-vars                
		const token = parsedUserData.token;
                if ( myvip == true ) {
                    // 如果已登录，则执行路由跳转
                    this.$router.push({ path: '/photoview', query: { id: pid } });
                } else {
                    // 如果非VIP会员，则弹出对话框
                    Modal.confirm({
                        title: '提示',
                        content: 'VIP资源仅VIP会员可观看哦~',
                        okText: '立即开通',
                        onOk: () => {
                            // 处理登录逻辑，例如跳转至登录页面
                            this.$router.push({ path: '/userhome' });
                        },
                        cancelText: '关闭',
                        centered: true,
                    });
                }
            } else {
                // 如果未登录，则弹出对话框
                Modal.confirm({
                    title: '提示',
                    content: '需要登录后才可观看哦~',
                    okText: '立即登录',
                    onOk: () => {
                        // 处理登录逻辑，例如跳转至登录页面
                        this.$router.push({ path: '/login' });
                    },
                    cancelText: '关闭',
                    centered: true,
                });
            }
        },
        handlePageChange(page) {
            this.pageCurrent = page;
            this.$router.push({ path: '/photolist', query: { 'page': page } });
            this.fetchPhotoList();
            window.scrollTo(0, 0);
        },
    },
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
</style>